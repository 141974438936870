<template>
  <div>
    <dashboard-card
        :title="title"
        :popover="popover"
    >
      <div>
        <b-container fluid>
          <b-row align-v="stretch">
            <b-col>
              <div class="linechart-chart">
                <apexchart
                    :type="chartOptions.chart.type"
                    :height="chartOptions.chart.height"
                    :options="chart.options"
                    :series="chart.data"
                />
              </div>
            </b-col>
            <b-col class="linechart-detail" cols="12" md="auto" col>
              <div data-align-v="stretch" class="lcd-container">
                <div class="lcd-content" v-for="(item, index) in detailList" :key="'lcd-content-'+index">
                  <div class="lcd-item">
                    <div class="lcd-item-label">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="lcd-item-value">
                      <span>{{ item.value }}</span>
                      <span v-if="item.emphasis" class="lcd-item-value-emphasis">{{ item.emphasis }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </dashboard-card>
  </div>
</template>

<script>
import DashboardCard from '@/components/dashboards/dashboard-card'

export default {
  name: 'dashboard-detail-line-chart',
  components: { DashboardCard },
  props: {
    title: {
      type: String,
      required: true
    },
    popover: {
      type: String,
      required: true
    },
    seriesTitle: {
      type: String,
      required: true
    },
    seriesData: {
      type: Array,
      required: true
    },
    seriesLabel: {
      type: Array,
      required: true
    },
    detailList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    chartOptions: {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false
        }
      },
      colors: ['#77B6EA'],
      dataLabels: {
        enabled: true
      },
      stroke: {
        // curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: []
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        // title: {
        //   text: 'Month'
        // }
      },
      yaxis: {
        title: {
          // text: 'Temperature'
        }
        // min: 5,
        // max: 40
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    }
  }),
  computed: {
    chart () {
      return {
        options: {
          ...this.chartOptions,
          xaxis: { categories: this.seriesLabel },
          yaxis: { title: { text: this.seriesTitle } }
        },
        data: [{
          name: this.seriesTitle,
          data: this.seriesData
        }]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.lcd-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 150px;
  @media (max-width: 768px) and (min-width: 576px) {
    width: 100%;
    flex-direction: row;
    justify-content: unset;
  }
}

.lcd-item-label {
  font-weight: 500;
  font-size: 14px;
  color: #5F6368;
}

.lcd-item-value {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 13px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 24px;
  color: #2F3844;

  .lcd-item-value-emphasis {
    display: inline-block;
    padding: 5px 10px;
    font-weight: 400;
    font-size: 14px;
    background: rgba(1, 179, 102, 0.31);
    border-radius: 5px;
  }
}

.linechart-chart {
  width: 100%;
  max-width: calc(100% - 20px);
  @media (max-width: 768px) {
    max-width: unset;
  }
}

.linechart-detail {
  &::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: #2C343B3B;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 768px) {
      content: none;
    }
  }
}

.lcd-content {
  height: 100%;
  display: grid;
  place-items: center;
  @media (max-width: 768px) {
    margin-right: 1rem;
  }
  @media (max-width: 576px) {
    margin-bottom: 1rem;
  }

  &:not(:first-child) {
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #2C343B3B;
      position: absolute;
      left: 0;
      top: 0;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
}
</style>
